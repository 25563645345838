<template lang="pug">
filter-template.info-filter(
  @reset="cleanFilter"
  @save="validateForm"
  @cancel="cancel"
)
  template(#header) Компания
  template(#content)
    form-input(
      v-model="filters.title"
      label="Название компании"
      placeholder="Введите название компании"
    )
    form-input(
      v-model="filters.login"
      label="Почта клиента"
      placeholder="Введите email пользователя"
      @error="errorChecker('form_email')"
      @correct="correctChecker('form_email')"
    )
    warning-message.info
      | Поиск осуществляется по почте всех зарегистрированных пользователей.
      |
      | Поиск работает по частичному соответствию (подстроке) - можно указать как весь почтовый адрес, так и его часть
</template>

<script lang="ts">
import { defineComponent, type PropType, toRefs } from 'vue'
import { getDefaultPreFiltrationFormClients, type PreFiltrationFormClientsI } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useForm } from "@/use/other/useForm";

import FormInput from "@/components/ui/form/input/FormInput.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import WarningMessage from "@/components/ui/alert/WarningMessage.vue";

export default defineComponent({
  name: "CompanyInfoFilter",
  components: {
    WarningMessage,
    FormInput,
    FilterTemplate,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormClientsI>,
      default: getDefaultPreFiltrationFormClients(),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormClientsI>(modelValue, false, ['title', 'login'], emit, 'clients')

    const { validateForm, errorChecker, correctChecker } = useForm(applyFilters);

    return {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
      errorChecker,
      correctChecker,
      validateForm,
    }
  }
})
</script>

<style scoped lang="scss">
.info-filter {
  width: 460px;

  :deep(.warning span) {
    font-size: 12px;
  }
}

.info {
  margin-top: 8px;
}
</style>
