<template lang="pug">
settings-panel
  template(#header) Список доступных компаний
  #companies-table
    ui-table(
      :data="data"
      :loader="isFetching"
      :columns="columns"
      :has-error="error"
      :show-clear="hasFiltersChanges"
      :header-sticky="true"
      :small-cell="true"
      no-data-text="Список доступных компаний пуст"
      no-data-description="none"
      v-model="preFiltrationForm"
      @apply="jumpToPage(1)"
      @clear="clearFilters"
    )
      template(#title="{ rowData }")
        .value
          .row
            ui-warning(v-if="rowData.isBlocked && rowData.reason" :label="rowData.reason" size="small" placement="bottom-start")
            span(:class="rowData.isBlocked && 'blocked'") {{ rowData.title }}
          span.email {{ rowData.creatorEmail }}
          .row(v-if="rowData.isTest || rowData.isTrialTariff" style="margin-top: 2px;")
            ui-tag(v-if="rowData.isTest" name="Служебный" size="small")
            ui-tag(v-if="rowData.isTrialTariff" name="Тестовый период" size="small")
      template(#inn="{ rowData }")
        .value
          .inn
            span.inn-label ИНН:
            span {{ rowData.inn || '-' }}
          .kpp
            span.kpp-label КПП:
            span {{ rowData.kpp || '-' }}
      template(#kpp="{ rowData }") {{ rowData.kpp || '-' }}
      template(#dateCreate="{ rowData }")
        .value(:class="!rowData.dateCreate && 'empty'") {{ rowData.dateCreate ? formatDateTime(rowData.dateCreate) : 'Нет данных' }}
      template(#dateEndTariff="{ rowData }")
        .row
          .value(:class="[!rowData.dateEndTariff && 'empty', checkAccountExpired(rowData.dateEndTariff) && 'expired']") {{ rowData.dateEndTariff ? rowData.dateEndTariff?.replaceAll('-', '.') : 'Нет данных' }}
          a(v-if="rowData.crmOrderLink" :href="rowData.crmOrderLink" target="_blank")
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Link"
              tooltip-title="Ссылка на заказ в ЦРМ"
            )
      template(#manager="{ rowData }")
        .value(:class="!rowData.companyManagers.length && 'empty'")
          span(v-if="!rowData.companyManagers.length") Нет данных
          span(v-for="companyManager of rowData.companyManagers" :key="companyManager.id") {{ getNameInitials(companyManager.manager) }}
      template(#clearIcon="{ rowData }")
        .value.actions
          a(v-if="rowData.crmCompanyLink" :href="rowData.crmCompanyLink" target="_blank")
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Link"
              tooltip-title="Ссылка на компанию в ЦРМ"
            )
          icon-button(
            v-else
            :size="16"
            :icon="UiIconNames.Icon_Plus"
            tooltip-title="Добавить номер компании в ЦРМ"
            @click="addCrmCompanyId(rowData)"
          )
          icon-button(
            :size="16"
            :icon="UiIconNames.Icon_User"
            tooltip-title="Править список менеджеров"
            @click="selectManager(rowData)"
          )
          icon-button(
            :size="16"
            :icon="UiIconNames.Icon_Next"
            tooltip-title="Перейти в аккаунт клиента"
            @click="selectCompany(rowData)"
          )

    ui-pagination(
      :count-results="data.length"
      :total-results="totalResults"
      :current-page="preFiltrationForm.page"
      :total="totalPages"
      @update:current-page="jumpToPage($event, false)"
    )

manager-appointment-dialog(
  v-if="showManagersDialog"
  v-model:show="showManagersDialog"
  v-model:company="selectedAppointmentCompany"
)
crm-company-dialog(
  v-if="showCrmNumberDialog"
  v-model:show="showCrmNumberDialog"
  v-model:company="selectedAppointmentCompany"
)
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue'
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useApi } from "@/use/api/useApi";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { getDefaultPreFiltrationFormClients } from "@/utils/getters/defaultFilters";
import { getFullName, getNameInitials } from "@/utils/formatter/nameFormatter";
import { parse } from "date-fns";
import { scrollIntoView } from "@/utils/scroller/documentScroller";
import { formatDateTime } from "~/utils/formatter/dateFormatter";
import { getDateString } from "~/use/filters/useFiltersPanel";

import isEqual from "lodash/isEqual";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";

import UiTag from "~/components/ui/tag/UiTag.vue";
import UiTable from "~/components/ui/table/UiTable.vue";
import UiPagination from "@/components/ui/pagination/UiPagination.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";

import CompanyInnFilter from "@/components/filters/companies/CompanyInnFilter.vue";
import CompanyInfoFilter from "@/components/filters/companies/CompanyInfoFilter.vue";
import CompanyManagerFilter from "@/components/filters/companies/CompanyManagerFilter.vue";
import CrmCompanyDialog from "~/components/pages/admin/managers/CrmCompanyDialog.vue";
import DateEndTariffFilter from "~/components/filters/companies/DateEndTariffFilter.vue";
import DateRegistrationFilter from "~/components/filters/companies/DateRegistrationFilter.vue";

import { Tabs } from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import type { ExtendedCompanyItemI } from "@/stores/auth/UserInterface";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";

export default defineComponent({
  name: "CompaniesList",
  methods: {
    formatDateTime,
    getNameInitials,
    getFullName,
  },
  components: {
    UiWarning,
    CrmCompanyDialog,
    UiTag,
    UiTable,
    UiPagination,
    IconButton,
    SettingsPanel,
    ManagerAppointmentDialog: defineAsyncComponent(() => import("@/components/pages/admin/managers/ManagerAppointmentDialog.vue")),
  },
  setup() {

    const { me } = useUserAccess();

    const managerStore = useTmManagerStore();
    const {
      selectedCompanyInfo,
    } = storeToRefs(managerStore);

    const router = useRouter();

    const data = ref<ExtendedCompanyItemI[]>([]);
    const error = ref(false);

    const isFetching = ref(true);
    const showManagersDialog = ref(false);
    const showCrmNumberDialog = ref(false);
    const selectedAppointmentCompany = ref<ExtendedCompanyItemI|null>();  // для диалога назначения менеджера

    const totalPages = ref(1);
    const totalResults = ref(0);

    const preFiltrationForm = ref(getDefaultPreFiltrationFormClients());

    function getInfoFilterValue() {
      return [preFiltrationForm.value.title, preFiltrationForm.value.login].filter(Boolean).join(', ')
    }

    function getCompanyCodes() {
      return [
        preFiltrationForm.value.inn ? `ИНН: ${ preFiltrationForm.value.inn }` : null,
        preFiltrationForm.value.kpp ? `КПП: ${ preFiltrationForm.value.kpp }` : null,
      ].filter(Boolean).join(', ')
    }

    function checkAccountExpired(dateEndTariff: string) {
      return dateEndTariff
          ? new Date() > parse(dateEndTariff + ' +0300', 'dd-MM-yyyy HH:mm x', new Date())
          : false;
    }

    const columns = computed(() => ({
      title: { size: 314, headerLabel: 'Название компании', placement: 'bottom-start', filterComponent: CompanyInfoFilter, value: getInfoFilterValue() },
      inn: { size: 172, headerLabel: 'ИНН, КПП', filterComponent: CompanyInnFilter, value: getCompanyCodes() },
      dateCreate: { size: 152, headerLabel: 'Дата регистрации', isSort: true, filterComponent: DateRegistrationFilter, value: getDateString(preFiltrationForm.value.dateCreate) },
      dateEndTariff: { size: 172, headerLabel: 'Дата окончания тарифа', isSort: true, filterComponent: DateEndTariffFilter, value: getDateString(preFiltrationForm.value.dateEndTariff) },
      manager: { size: 162, headerLabel: 'Менеджер', filterComponent: CompanyManagerFilter, value: preFiltrationForm.value.manager?.fio || '' },
      clearIcon: { size: 100 },
    }))

    const hasFiltersChanges = computed(() => !isEqual(preFiltrationForm.value, getDefaultPreFiltrationFormClients()));

    /** получение списка компаний */
    useApi().admin.fetchManagersCompanies(preFiltrationForm.value)
      .then((hydraData: any) => {
        totalResults.value = Number(hydraData['hydra:totalItems'])
        totalPages.value = Math.ceil(hydraData['hydra:totalItems'] === 0 ? 1 : (hydraData['hydra:totalItems'] / 30))
        data.value = hydraData['hydra:member']
      })
      .catch(() => error.value = true)
      .finally(() => isFetching.value = false)

    function selectCompany(company: any) {
      selectedCompanyInfo.value = company;
      router.push({ name: Tabs.AdminPages.PageTariffChanger })
    }

    function selectManager(company: any) {
      selectedAppointmentCompany.value = company;
      showManagersDialog.value = true;
    }

    function addCrmCompanyId(company: any) {
      selectedAppointmentCompany.value = company;
      showCrmNumberDialog.value = true;
    }

    function clearFilters() {
      preFiltrationForm.value = getDefaultPreFiltrationFormClients();
      jumpToPage(1)
    }

    function jumpToPage(p: number, resetPages = true) {
      preFiltrationForm.value.page = p
      scrollIntoView('companies-table', 'start', 'instant')
      applyFilters(resetPages)
    }

    /** фильтрация компаний */
    function applyFilters(resetPages: boolean) {
      isFetching.value = true;

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      data.value = []
      error.value = false

      useApi().admin.fetchManagersCompanies(preFiltrationForm.value)
        .then((filtersData) => {
          totalResults.value = Number(filtersData['hydra:totalItems'])
          totalPages.value = Math.ceil(filtersData['hydra:totalItems'] === 0 ? 1 : (filtersData['hydra:totalItems'] / 30))
          data.value = filtersData['hydra:member']
        })
        .catch(() => error.value = true)
        .finally(() => isFetching.value = false)
    }

    return {
      me,
      data,
      error,
      isFetching,
      totalPages,
      totalResults,
      preFiltrationForm,
      columns,
      hasFiltersChanges,
      addCrmCompanyId,
      checkAccountExpired,
      jumpToPage,
      clearFilters,
      selectCompany,
      selectManager,
      showCrmNumberDialog,
      selectedAppointmentCompany,
      showManagersDialog,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
#companies-table {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.row {
  display: flex;
  flex-flow: row;
  gap: 4px;
}

.value {
  overflow-wrap: anywhere;
  font-size: 14px;
  display: flex;
  flex-flow: column;
  gap: 4px;

  &.empty {
    color: #85899b;
  }

  &.expired {
    color: var(--main-red-color);
  }

  &.actions {
    display: flex;
    flex-flow: row;
    gap: 0;
    margin-left: auto;
  }
}

.email {
  font-size: 13px;
  color: var(--secondary-text-color);
}

.inn, .kpp {
  display: flex;
  flex-flow: row;
  gap: 8px;

  .inn-label, .kpp-label {
    font-size: 11px;
    color: var(--secondary-text-color);
  }
}

.blocked {
  color: var(--main-red-color);
}
</style>
