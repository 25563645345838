<template lang="pug">
filter-template.date-filter(
  show-sorting
  :sorting-order="filters.sorting.find(e => e.title === 'dateCreate')?.order"
  @save="applyFilters"
  @cancel="cancel"
  @set-order="setOrder"
  @reset="cleanFilter"
)
  template(#header) Дата регистрации
  template(#content)
    date-filter-card(
      date-type="dateCreate"
      :date-value="filters.dateCreate"
      @update:date="filters.dateCreate = $event.value"
    )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { getDefaultPreFiltrationFormClients, type PreFiltrationFormClientsI } from "@/utils/getters/defaultFilters";
import { defineComponent, toRefs } from "vue";
import { setSorting } from "@/utils/filters/sorting";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";

import DateFilterCard from "@/components/searchForm/elements/DateFilterCard.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";

export default defineComponent({
  name: "DateCreateFilter",
  components: {
    DateFilterCard,
    FilterTemplate,
  },
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormClientsI>,
      default: getDefaultPreFiltrationFormClients(),
    },
  },
  emits: [
    'update:modelValue',
    'close',
    'apply',
  ],
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormClientsI>(modelValue, false, ['dateCreate'], emit, 'clients')

    function setOrder(mode: 'asc'|'desc'|'') {
      filters.value.sorting = setSorting('dateCreate', mode, []);
    }

    return {
      filters,
      cancel,
      applyFilters,
      cleanFilter,
      setOrder,
    };
  }
});
</script>

<style scoped lang="scss">
.date-filter {
  width: 460px;
}
</style>
