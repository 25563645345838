<template lang="pug">
settings-panel(v-loading="!tariffsList.length")
  template(#header) Общие сведения
  .dashboard
    .statistics
      .circle-graph
        .progress
          el-progress(type="circle" :percentage="Math.ceil(activeCompaniesCount * 100 / companiesCount)") {{ activeCompaniesCount }}
          .percentage
            span {{ `${ Math.ceil(activeCompaniesCount * 100 / companiesCount)}%` }}
        .description Кол-во активных аккаунтов

      .circle-graph
        el-progress(type="circle" :percentage="100") {{ companiesCount }}
        .description Кол-во зарегистрированных аккаунтов

    .circle-graph
      VChart.chart(:option="option")
      .description Статистика активных аккаунтов по подключенным тарифам
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import VChart from "vue-echarts";

import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { use } from "echarts/core";
import { storeToRefs } from "pinia";
import useManualsStore from "@/stores/manuals/useManualsStore";

use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
])

export default defineComponent({
  name: "RegistrationDashboard",
  components: {
    VChart,
    SettingsPanel,
  },
  setup() {

    const companiesCount = 240;
    const activeCompaniesCount = 56;

    const byTariff = [
      { tariffId: 1, count: 16, },
      { tariffId: 2, count: 33, },
      { tariffId: 4, count: 3, },
      { tariffId: 8, count: 4, },
    ]

    const manualsStore = useManualsStore();
    const { tariffsList } = storeToRefs(manualsStore);

    function getOptions() {
      return {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: byTariff.map(e => tariffsList.value.find(t => t.id === e.tariffId)?.title),
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Количество активных аккаунтов',
            type: 'bar',
            barWidth: '60%',
            data: byTariff.map(e => e.count)
          }
        ]
      }
    }

    const option = ref(getOptions())

    if (!tariffsList.value.length) {
      manualsStore.manuals.getTariffsList()
        .then(() => option.value = getOptions())
    }

    return {
      tariffsList,
      companiesCount,
      activeCompaniesCount,
      byTariff,
      option,
    }
  }
})
</script>

<style scoped lang="scss">
.chart {
  width: 100%;
  height: 100%;
}

.dashboard {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 64px;
}

.statistics {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

.progress {
  width: fit-content;
  height: fit-content;
  position: relative;

  .percentage {
    position: absolute;
    font-size: 11px;
    font-weight: bold;
    top: -2px;
    right: -8px;

    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #e0e0e0;
    background-color: var(--main-color-white);
    border-radius: 50%;
  }
}

.circle-graph {
  display: flex;
  flex-flow: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;

  .description {
    font-size: 14px;
  }

  .content {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
}
</style>
