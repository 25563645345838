<template lang="pug">
#managers-list
  h1.main-page-title Панель администратора
  managers-list
  companies-list
  //registration-dashboard
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ManagersList from "@/components/pages/admin/managers/ManagersList.vue";
import CompaniesList from "@/components/pages/admin/managers/CompaniesList.vue";
import RegistrationDashboard from "@/components/pages/admin/managers/RegistrationDashboard.vue";

export default defineComponent({
  name: "Index",
  components: {
    ManagersList,
    CompaniesList,
    RegistrationDashboard,
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#managers-list {
  @include page-view;

  display: flex;
  flex-flow: column;
  gap: 32px;
}
</style>
