<template lang="pug">
filter-template.manager-filter(
  @reset="cleanFilter"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#header) Ответственный менеджер
  template(#content)
    manager-selector(
      filterable
      is-tm-manager
      label="Менеджер"
      v-model="filters.manager"
      :teleported="false"
    )
</template>

<script lang="ts">
import { defineComponent, type PropType, toRefs } from 'vue'
import { getDefaultPreFiltrationFormClients, type PreFiltrationFormClientsI } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import ManagerSelector from "@/components/selector/ManagerSelector.vue";

export default defineComponent({
  name: "CompanyManagerFilter",
  components: {
    ManagerSelector,
    FilterTemplate,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormClientsI>,
      default: getDefaultPreFiltrationFormClients(),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormClientsI>(modelValue, false, ['manager'], emit, 'clients')

    return {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.manager-filter {
  width: 320px;
}
</style>
