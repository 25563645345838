<template lang="pug">
filter-template.inn-filter(
  @reset="cleanFilter"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#header) ИНН, КПП
  template(#content)
    number-type-input(v-model="filters.inn" placeholder="Фильтр по ИНН" label="ИНН")
    number-type-input(v-model="filters.kpp" placeholder="Фильтр по КПП" label="КПП")
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { PreFiltrationFormClientsI } from "@/utils/getters/defaultFilters";

import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import NumberTypeInput from "@/components/ui/form/input/NumberAsTextInput.vue";

import { defineComponent, toRefs } from 'vue'
import { getDefaultPreFiltrationFormClients } from "@/utils/getters/defaultFilters";
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";

export default defineComponent({
  name: "CompanyInnFilter",
  components: {
    NumberTypeInput,
    FilterTemplate,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormClientsI>,
      default: getDefaultPreFiltrationFormClients(),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormClientsI>(modelValue, false, ['inn', 'kpp'], emit, 'clients')

    return {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.inn-filter {
  width: 320px;
}
</style>
