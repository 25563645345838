<template lang="pug">
ui-dialog(
  modal
  closable
  width="400px"
  :visible-prop="show"
  header="Создание ссылки на компанию в ЦРМ"
  @content:hide="hide"
)
  template(#content)
    .crm-content
      span.crm-label Введите номер компании <b>{{ company.title }}</b> в ЦРМ
      validation-row(
        custom
        not-empty
        v-model="id"
        placeholder="Укажите номер компании"
        :custom-checker="customNumberChecker"
        @error="errorChecker('id')"
        @correct="correctChecker('id')"
      )
      span.error-message(v-if="isError") Не удалось обновить данные
  template(#actions)
    ui-button(type="secondary" @click.stop="hide") Отменить
    ui-button(type="secondary" :loader="isSaving" @click.stop="validateForm") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useApi } from "~/use/api/useApi";
import { useVModel } from "@vueuse/core";

import UiDialog from "~/components/ui/dialog/UiDialog.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import ValidationRow from "~/components/ui/form/input/ValidationRow.vue";
import type { ExtendedCompanyItemI } from "~/stores/auth/UserInterface";
import { useForm } from "~/use/other/useForm";
import { customNumberChecker } from "~/utils/checkers/checkers";

export default defineComponent({
  name: "CrmCompanyDialog",
  methods: {
    customNumberChecker,
  },
  components: {
    UiButton,
    UiDialog,
    ValidationRow,
  },
  emits: [
    'update:show',
    'update:company',
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object as PropType<ExtendedCompanyItemI>,
      default: () => ({}),
    },
  },
  setup(props, context) {

    const id = ref(null);

    const isSaving = ref(false);
    const isError = ref(false);

    const companyModel = useVModel(props, 'company', context.emit);

    function hide() {
      context.emit('update:show', false)
    }

    function save() {

      isSaving.value = true;
      isError.value = false;

      useApi().admin.applyCrmNumberToCompany(props.company.id, { crmCompanyId: Number(id.value) })
        .then((value: any) => {
          if (value) companyModel.value = value
          hide()
        })
        .catch(() => isError.value = true)
        .finally(() => isSaving.value = false)

    }

    const { validateForm, errorChecker, correctChecker } = useForm(save);

    return {
      hide,
      validateForm,
      correctChecker,
      errorChecker,
      id,
      isSaving,
      isError,
    }
  }
})
</script>

<style scoped lang="scss">
.crm-content {
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.crm-label {
  font-size: 14px;
  line-height: 20px;
}

.error-message {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-red-color);
}
</style>
